.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem 2rem;
  border-radius: var(--border-radius-sm);
}

.desktopRight {
  flex-direction: row;
  align-items: flex-start;
}

.desktopLeft {
  flex-direction: row-reverse;
  align-items: flex-start;
}

.bannerDetails {
  flex: 1;
}

.bannerImage {
  max-width: 50%;
}

.bannerCTAs {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .root {
    flex-direction: column;
    gap: 1rem;
    padding: 20px;
  }

  .bannerDetails,
  .bannerImage,
  .bannerCTAs {
    flex: none;
    max-width: 100%;
  }
}
