.ctaBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.cta-1,
.cta-2 {
  font-size: var(--font-md);
  font-weight: var(--font-weight-medium);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: var(--br-xs);
}

.cta-1 {
  background-color: var(--white);
  border: 1px solid var(--border-gray);
}

.cta-2 {
  background-color: var(--theme-yellow);
}

@media screen and (max-width: 767px) {
  .ctaBtns {
    gap: 10px;
  }
}
